<template>
  <div class="component-more">
    <span @click="handleClick">{{ text }}</span>
  </div>
</template>

<script>
import { throttle } from 'lodash'
import i18n from '@/i18n/i18n'

export default {
  props: {
    page: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    totalPage: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    text() {
      if (this.loading) {
        return i18n.locale === 'ch' ? '加载中...' : 'Loading...'
      }

      if (this.page >= this.totalPage) {
        return i18n.locale === 'ch' ? '没有更多可加载' : 'No more to load'
      }

      return i18n.locale === 'ch' ? '点击加载更多' : 'Click load more'
    },
  },
  methods: {
    handleClick: throttle(function () {
      if (this.loading) {
        return false
      }

      const page = this.page + 1
      if (page > this.totalPage) {
        return false
      }

      this.$emit('load', page)
    }, 2000),
  },
}
</script>

<style scoped lang="scss">
.component-more {
  text-align: center;
  margin: 20px 0;
  > span {
    padding: 10px 0;
    cursor: pointer;
    font-size: 12px;
    color: #bcbccb;
  }
}
</style>
