<template>
<div class="exercises-empty">
  <div class="empty-img"/>
  <div class="empty-text">暂无记录</div>
  <div class="empty-text" v-if="isTabs">快来占领练习区的沙发吧!</div>
</div>
</template>

<script>
export default {
  props: {
    isTabs: {
      type: Boolean,
      default: false,
    }
  }
}
</script>
<style lang='less'>
.exercises-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px 0;
  .empty-img {
    width: 190px;
    height: 200px;
    margin-bottom: 16px;
    background: url(../assets/empty-bg.png) no-repeat;
  }
  .empty-text {
    font-size:12px;
    color: #BCBCCB;
    line-height:20px;
  }
}
</style>