<template>
  <el-popover
    v-model="dialogVisible"
    placement="top"
    trigger="manual"
    content="请先点击播放进行加载哦"
  >
    <div class="audio-content" slot="reference">
      <div class="controls">
        <i
          class="f-icon"
          :class="onOff ? 'icon-pause-audio' : 'icon-play-audio'"
          @click="handleControl"
        />
      </div>
      <el-slider
        class="waveform"
        v-model="audio"
        :disabled="audio <= 0"
        :min="0"
        :max="maxTime"
        id="slider"
        ref="slider"
        :step="0.1"
        :show-tooltip="false"
        input-size="mini"
        tooltip-class="tooltipClass"
        @change="handleAudioChange"
        @mousedown.native="handleSlideTouch"
      >
      </el-slider>
      <audio
        preload="none"
        :src="url"
        id="music"
        @timeupdate="timeupdate"
        @loadedmetadata="loadedmetadata"
        @canplaythrough="canplaythrough"
        ref="music"
      ></audio>
      <div class="audio-content-time">{{ currentTime }} / {{ endTime }}</div>
      <!--      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">-->
      <!--        <span>请先点击播放进行加载哦</span>-->
      <!--        <span slot="footer" class="dialog-footer">-->
      <!--          <el-button @click="dialogVisible = false">取 消</el-button>-->
      <!--          <el-button-->
      <!--            style="padding: 12px 20px"-->
      <!--            type="primary"-->
      <!--            @click="dialogVisible = false"-->
      <!--            >确 定</el-button-->
      <!--          >-->
      <!--        </span>-->
      <!--      </el-dialog>-->
    </div>
  </el-popover>
</template>

<script>
import { debounce } from 'lodash'
export default {
  props: {
    url: {
      type: String
    },
    id: null,
    currentId: null
  },
  data() {
    return {
      currentTime: '00:00', // 当前播放位置
      endTime: '00:00', // 时间总长度
      maxTime: 100,
      onOff: false, // 控制播放暂停按钮
      audio: 0, // 音频播放的位置和音频的时间
      preload: 'none',
      dialogVisible: false
    }
  },
  watch: {
    currentId() {
      if (this.id && this.currentId !== this.id) {
        this.$refs.music.pause()
      }
    }
  },
  methods: {
    // 是否加载完成
    canplaythrough() {
      this.endTime = this.formateSeconds(this.$refs.music.duration || 0)
      if (this.id) {
        this.$emit('palyAudio', this.id)
      }
      this.$refs.music.play()
      this.onOff = true
    },
    formateSeconds(time) {
      let secondTime = Math.floor(time)
      let min = 0
      if (secondTime > 60) {
        min = parseInt(secondTime / 60)
        secondTime = parseInt(secondTime % 60)
      }
      return `${min
        .toString()
        .padStart(2, '0')}:${secondTime.toString().padStart(2, '0')}`
    },

    handleControl() {
      if (this.onOff) {
        this.playClick()
      } else {
        this.stopClick()
      }
    },

    // 暂停
    playClick() {
      this.$refs.music.pause()
      this.onOff = false
    },
    // 播放
    stopClick() {
      this.canplaythrough()
    },
    // 音频实时播放信息
    timeupdate(e) {
      if (!this.onOff) {
        return
      }

      let min = '0' + parseInt(e.target.currentTime / 60)
      let sec = parseInt(e.target.currentTime) % 60
      if (sec < 10) {
        sec = '0' + sec
      }
      if (this.onOff) {
        this.currentTime = min + ':' + sec
        this.audio = e.target.currentTime
      }
      // 如果当前时间等于总时间按钮变成停止状态
      if (e.target.currentTime === this.$refs.music.duration) {
        this.onOff = false
      }
    },
    // 获取音频信息
    loadedmetadata(e) {
      this.maxTime = e.target.duration
    },
    // 改变位置
    onChange(val) {
      if (!this.$refs.music.currentTime) {
        // this.dialogVisible = true
        this.audio = 0
        return
      } else {
        this.$refs.music.pause()
        this.onOff = false
      }
    },
    handleSlideTouch() {
      if (!this.$refs.music.currentTime) {
        return
      }

      this.onOff = false
      this.$refs.music.pause()
    },
    handleAudioChange() {
      if (!this.$refs.music.currentTime) {
        return
      }

      this.handlePlay()
    },
    handlePlay: debounce(function() {
      this.$refs.music.currentTime = this.audio
      this.canplaythrough()
    }, 500)
  }
}
</script>
<style lang="less">
.audio-content {
  display: flex;
  align-items: center;
  width: 268px;
  height: 36px;
  border: 1px solid #ebebf2;
  border-radius: 10px;
  background-color: #fff;
  .controls {
    width: 34px;
    color: #3b86ff;
    i {
      padding-left: 10px;
    }
  }
  .waveform {
    flex: 1;
    margin-left: 5px;
    wave {
      border: none !important;
      overflow: hidden !important;
    }
  }
  .audio-content-time {
    padding-left: 10px;
    padding-right: 6px;
    font-size: 12px;
    color: #c7cade;
  }
}
.el-slider__runway {
  height: 4px;
  &.disabled {
    .el-slider__button {
      border-color: transparent;
    }
  }
}
.el-slider__button {
  width: 12px;
  height: 12px;
  border: 2px solid #ffffff;
  box-shadow: 0px 0px 2px #888888;
}
.el-slider__bar {
  background-color: #5d9bf6;
}
</style>
